import React, { useState } from "react";
import "./NavBar.scss";
import { ReactComponent as Mouse } from "./svg/mouse.svg";
import { ReactComponent as Settings } from "./svg/setting.svg";
import { ReactComponent as Avatar } from "./svg/222.svg";
import { ReactComponent as Arrow } from "./svg/arrow.svg";
import { ReactComponent as User } from "./svg/user.svg";
import { useNavigate } from "react-router-dom";
import { messages } from "../../../i18n/messages";

type NavBarTypeProps = {
  id: number;
  title: string;
  Image: React.FC<React.SVGProps<SVGSVGElement>>;
};
type NavBarProps = {
  onShowCompanyList: (showCompanyList: boolean) => void;
  onShowUpdatePage: (showUpdatePage: boolean) => void;
  onShowUserPage: (showUserPage: boolean) => void;
};

function NavBar(props: NavBarProps) {
  const navigate = useNavigate();
  const [activeItemMenu, setIsActiveMenu] = useState(1);
  const navigations: Array<NavBarTypeProps> = [
    { id: 1, title: messages.companyList, Image: Mouse },
    { id: 2, title: messages.usersList, Image: User },
    { id: 3, title: messages.updateSoftware, Image: Settings },
    { id: 4, title: messages.log_out, Image: Arrow },
  ];
  function onChangeActive(id: number) {
    setIsActiveMenu(id);
    props.onShowCompanyList(true);
    switch (id) {
      case 1:
          props.onShowCompanyList(true);
        break;
      case 2:
          props.onShowUserPage(true);
        break;
      case 3:
          props.onShowUpdatePage(true);
        break;
      case 4: {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiration");
        localStorage.removeItem("expirationRefreshToken");
        navigate("/login");
      }
    }
  }
  return (
    <nav className="navBarContainer">
      <div className="avatar flex items-center justify-center">
        <Avatar alt="Avatar" />
      </div>
      <div className="companyOurName flex flex-col items-center justify-center">
        <p className="job">{messages.nameOurCompany}</p>
      </div>
      <div className="navigation">
        <ul>
          {navigations.map(({ id, title, Image }) => (
            <li onClick={() => onChangeActive(id)} key={id}>
              <div className="navigation_point flex">
                <Image className={activeItemMenu === id ? "active" : ""} />
                <span
                  className={`pl-2 ${activeItemMenu === id ? "active" : ""}`}
                >
                  {title}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
export default NavBar;
