import React, { useEffect, useMemo, useState } from "react";
import "./CreateEdit.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { CompanyDTO } from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import { NotificationType } from "../../../Notification/notificationTypes";
import { messages } from "../../../../i18n/messages";
import ModalNotification from "../../UpdatePage/Uploader/Modal/ModalNotification";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../store/notificationSlice";
import { AppDispatch } from "../../../../store";

type CreateCompanyProps = {
  getCompanies: () => void;
  setShowCreateCompany: (showCreateCompany: boolean) => void;
  currentCompany: CompanyDTO;
};

function CreateEditCompany(props: CreateCompanyProps) {
  const [showModalNotification, setShowModalNotification] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CompanyDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return props.currentCompany;
    }, [props]),
  });

  useEffect(() => {
    reset(props.currentCompany);
  }, [props.currentCompany, reset]);

  async function createCompany(data: CompanyDTO) {
    let response;
    try {
      response = await mapClient.createCompany(data);
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.createCompany_success,
          }),
        );
        props.setShowCreateCompany(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.createCompany_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getCompanies();
  }

  async function updateCompany(data: CompanyDTO) {
    let response;
    try {
      console.log(data.creationDate);
      console.log(data.creationDate!.toString());
      console.log(new Date(data.creationDate!.toString()+"Z"));
      response = await mapClient.updateCompany({
        ...data,
        creationDate: data.creationDate === undefined ? undefined : new Date(data.creationDate!.toString()+"Z"),
      }as CompanyDTO);
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.updateCompany_success,
          }),
        );
        props.setShowCreateCompany(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.updateCompany_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getCompanies();
  }

  const onSubmit: SubmitHandler<CompanyDTO> = async (data) => {
    if (data.id !== undefined) {
      await updateCompany(data);
    } else {
      await createCompany(data);
    }
  };

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={
              props.currentCompany.id
                ? messages.update_company_question
                : messages.create_company_question
            }
            onClose={() => setShowModalNotification(false)}
            onAcceptCloseForm={() => props.setShowCreateCompany(false)}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="createContainer">
          <div className="createEditForm">
            <div className="createHeader flex justify-between items-center">
              <h3>
                {props.currentCompany.id
                  ? messages.edit_company_title
                  : messages.create_company_title}
              </h3>
              <img
                src="/image/btn-remove/btn-remove.svg"
                onClick={() => setShowModalNotification(true)}
                alt="Escape"
              />
            </div>
            <div className="createBlock mt-4">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                className="createBlockForm"
              >
                <label htmlFor="name">{messages.create_company_name}</label>
                <input
                  className={`${errors?.name?.message ? "input__error" : ""}`}
                  {...register("name", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="name"
                  placeholder={messages.placeholder_name_company}
                />
                <div>
                  {errors?.name && <p>{errors?.name?.message || "Errors"}</p>}
                </div>
                <label htmlFor="vehicleServiceHost">
                  {messages.company_server_address}
                </label>
                <input
                  className={`${errors?.host?.message ? "input__error" : ""}`}
                  {...register("host", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="host"
                  placeholder={messages.placeholder_server_address}
                />
                <div>
                  {errors?.host && <p>{errors?.host?.message || "Errors"}</p>}
                </div>
                <label htmlFor="backupHost">
                  {messages.company_backupHost}
                </label>
                <input
                  {...register("backupHost")}
                  type="text"
                  id="backupHost"
                  placeholder={messages.placeholder_backupHost}
                />

                <label htmlFor="vehiclePort">
                  {messages.company_vehicle_port}
                </label>
                <input
                  {...register("vehiclePort")}
                  type="text"
                  id="vehiclePort"
                  placeholder={messages.placeholder_vehicle_port}
                />
                <label htmlFor="brokerPort">
                    {messages.company_broker_port}
                </label>
                <input
                    {...register("brokerPort")}
                    type="text"
                    id="brokerPort"
                    placeholder={messages.placeholder_vehicle_port_broker}
                />
                <label htmlFor="keepAliveInterval">
                  {messages.keep_alive_interval}
                </label>
                <input
                  className={`${
                    errors?.keepAliveInterval?.message ? "input__error" : ""
                  }`}
                  {...register("keepAliveInterval", {
                    required: messages.input_required,
                    pattern: {
                      value: /^-?\d+$/,
                      message: messages.positive_number,
                    },
                  })}
                  type="text"
                  id="keepAliveInterval"
                  placeholder={messages.placeholder_keep_alive_interval}
                />
                <div>
                  {errors?.keepAliveInterval && (
                    <p>{errors?.keepAliveInterval?.message || "Errors"}</p>
                  )}
                </div>
                <label htmlFor="email">{messages.company_email}</label>
                <input
                  className={`${
                    errors?.email?.message ? "input__error" : ""
                  }`}
                  {...register("email", {
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: messages.company_validate_email,
                    },
                  })}
                  type="email"
                  id="email"
                  placeholder={messages.placeholder_company_email}
                />
                <div>
                  {errors?.email && <p>{errors?.email?.message || "Errors"}</p>}
                </div>
                <label htmlFor="password">{messages.company_password}</label>
                <input
                  className={`${
                    errors?.password?.message ? "input__error" : ""
                  }`}
                  {...register("password", {
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                      message: messages.validate_password,
                    },
                  })}
                  type="password"
                  id="password"
                  placeholder={messages.placeholder_company_password}
                />
                <div>
                  {errors?.password && (
                    <p>{errors?.password?.message || "Errors"}</p>
                  )}
                </div>
                <div className="createButton">
                  <button type="submit" className="btn">
                    <span>{messages.save}</span>
                  </button>
                  <button
                    type="reset"
                    className="cancel"
                    onClick={() => setShowModalNotification(true)}
                  >
                    <span>{messages.cancel}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateEditCompany;
