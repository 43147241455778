import { Gender } from "../../../../api/auth/apiClient";

export const optionsDataGender = [
  {
    label: "Мужской",
    value: Gender._0,
  },
  {
    label: "Женский",
    value: Gender._1,
  },
];