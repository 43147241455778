import React, { useState } from "react";
import { FileUploader } from "@skbkontur/react-ui";
import "./Uploader.scss";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import { FileParameter } from "../../../../api/auth/apiClient";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NotificationType } from "../../../Notification/notificationTypes";
import ModalNotification from "./Modal/ModalNotification";
import { messages } from "../../../../i18n/messages";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../store/notificationSlice";
import { AppDispatch } from "../../../../store";

let styles = {
  width: "100%",
  marginTop: 20,
};
type ChangeFileParameter = {
  name: string;
  version: string;
  file: FileParameter | undefined;
};

type UploaderProps = {
  setIsPressed: (isPressed: boolean) => void;
  getFirmWare: () => void;
};
function Uploader(props: UploaderProps) {
  const [showModalNotification, setShowModalNotification] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ChangeFileParameter>({ mode: "onChange" });

  const onSubmit: SubmitHandler<any> = async (data) => {
    let response;
    try {
      response = await mapClient.uploadFirmware(
        data.name,
        data.file?.name,
        data.version,
        {
          fileName: data.file?.name,
          data: new Blob([data.file]),
        } as FileParameter,
      );
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.file_upload_success,
          }),
        );
        props.setIsPressed(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.file_upload_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getFirmWare();
  };

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={messages.uploader_question}
            onClose={() => setShowModalNotification(false)}
            onAcceptCloseForm={() => props.setIsPressed(false)}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="uploaderContainer">
          <div className="uploaderForm">
            <div className="uploaderHeader flex justify-between items-center">
              <h3>{messages.uploader_title}</h3>
              <img
                src="/image/btn-remove/btn-remove.svg"
                alt="Excape"
                onClick={() => setShowModalNotification(true)}
              />
            </div>
            <div className="uploaderBlock mt-4">
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className="uploaderBlockForm"
              >
                <label htmlFor="name">{messages.uploader_file_name}</label>
                <input
                  {...register("name", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="text"
                  placeholder={messages.placeholder_file_name}
                />
                <div>
                  {errors?.name && <p>{errors?.name?.message || "Errors"}</p>}
                </div>
                <label htmlFor="version">{messages.uploader_version}</label>
                <input
                  {...register("version", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="text"
                  placeholder={messages.placeholder_version}
                />
                <div>
                  {errors?.version && (
                    <p>{errors?.version?.message || "Errors"}</p>
                  )}
                </div>
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { name, value, onChange } }) => (
                    <FileUploader
                      multiple
                      name={name}
                      size="large"
                      style={styles}
                      id="file"
                      type="file"
                      value={value?.data}
                      onChange={(event) => {
                        onChange(event.target.files![0]);
                      }}
                       /*validateBeforeUpload={({ originalFile, validationResult, status }) => {
                         if (originalFile.type === "hex") {

                            /!*if (originalFile.name.split(".").at(-1) !== "hex") {
                                return Promise.reject(`У файла ${originalFile.name} неверный формат`);
                            }*!/
                          } else {
                           return  Promise.resolve(`У файла ${originalFile.name} неверный формат`);
                         }
                       }}*/
                    />
                  )}
                ></Controller>
                <div className="mt-5">
                  <button type="submit" className="btn">
                    <span>{messages.approve}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Uploader;
