import React from "react";
import HomePage from "./components/HomePage/HomePage";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import { PrivateRoute } from "./hoc/PrivateRoute";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
