import React from "react";
import "./Ports.scss";
import { messages } from "../../../../i18n/messages";
import { CompanyDTO } from "../../../../api/auth/apiClient";

type PortsProps = {
  currentCompany: CompanyDTO;
  setShowInfoPorts: (showInfoPorts: boolean) => void;
};

function Ports(props: PortsProps) {
  return (
    <div className="overlay">
      <div className="portsContainer">
        <div className="portsForm">
          <div className="portsHeader flex justify-between items-center">
            <h3>{messages.information_ports}</h3>
            <img
              src="/image/btn-remove/btn-remove.svg"
              onClick={() => props.setShowInfoPorts(false)}
              alt="Escape"
            />
          </div>
          <div className="ports_form_body">
            <div className="pb-2 flex items-center justify-between">
              <div>
                <span>{messages.vehiclePort}</span>
              </div>
              <div>
                <p className="pt-1">{props.currentCompany.vehiclePort}</p>
              </div>
            </div>
            <div className="pb-2 flex items-center justify-between">
              <div>
                <span>{messages.brokerPort}</span>
              </div>
              <div>
                <p className="pt-1">{props.currentCompany.brokerPort}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ports;
