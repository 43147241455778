import React, { useEffect, useMemo, useState } from "react";
import "./CreateEditUser.scss";
import Select from "react-select";
import { messages } from "../../../../i18n/messages";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RoleDTO, UserDTO } from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import { NotificationType } from "../../../Notification/notificationTypes";
import { optionsDataGender } from "./gender";
import { customStyles } from "./styleSelector";
import ModalNotification from "../../UpdatePage/Uploader/Modal/ModalNotification";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../../store/notificationSlice";
import { AppDispatch } from "../../../../store";
registerLocale("ru", ru);

type CreateEditUserProps = {
  setShowCreateUser: (showCreateUser: boolean) => void;
  getUsers: () => void;
  allRoles: Array<RoleDTO>;
  selectedUser: UserDTO;
};
function CreateEditUser(props: CreateEditUserProps) {
  const [showModalNotification, setShowModalNotification] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<UserDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return props.selectedUser;
    }, [props]),
  });
  useEffect(() => {
    reset(props.selectedUser);
  }, [props.selectedUser, reset]);

  async function createUser(data: UserDTO) {
    let response;
    try {
      response = await mapClient.createUser(data);
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.createUser_success,
          }),
        );
        props.setShowCreateUser(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.createUser_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getUsers();
  }

  async function updateUser(data: UserDTO) {
    let response;
    try {
      response = await mapClient.updateUser(data);
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.updateUser_success,
          }),
        );
        props.setShowCreateUser(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.updateUser_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getUsers();
  }
  const onSubmit: SubmitHandler<UserDTO> = async (data) => {
    if (data.id !== undefined) {
      await updateUser(data);
    } else {
      await createUser(data);
    }
  };

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={
              props.selectedUser.id
                ? messages.user_update_question
                : messages.user_create_question
            }
            onClose={() => setShowModalNotification(false)}
            onAcceptCloseForm={() => props.setShowCreateUser(false)}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="createContainer">
          <div className="createForm">
            <div className="createHeader flex justify-between items-center">
              <h3>
                {props.selectedUser.id
                  ? messages.update_user_title
                  : messages.create_new_user}
              </h3>
              <img
                src="/image/btn-remove/btn-remove.svg"
                alt="Escape"
                onClick={() => setShowModalNotification(true)}
              />
            </div>
            <div className="createBlock mt-5">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                className="createBlockForm"
              >
                <div className="createBlockColumns">
                  <div className="createBlockFirstColumn mr-8">
                    <div className="userName mb-2">
                      <label htmlFor="userName">{messages.userName}</label>
                      <input
                        {...register("userName", {
                          required: messages.input_required,
                        })}
                        type="text"
                        id="userName"
                        placeholder={messages.placeholder_userName}
                      />
                      <div>
                        {errors?.userName && (
                          <p>{errors?.userName?.message || "Errors"}</p>
                        )}
                      </div>
                    </div>

                    <div className="email mb-2">
                      <label htmlFor="email">{messages.email}</label>
                      <input
                        {...register("email", {
                          required: messages.input_required,
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: messages.validate_email,
                          },
                        })}
                        type="email"
                        id="email"
                        placeholder={messages.placeholder_email}
                      />
                      <div>
                        {errors?.email && (
                          <p>{errors?.email?.message || "Errors"}</p>
                        )}
                      </div>
                    </div>
                    <div className="login mb-2">
                      <label htmlFor="login">{messages.login_user}</label>
                      <input
                        {...register("login")}
                        type="text"
                        id="login"
                        placeholder={messages.placeholder_login_user}
                      />
                    </div>
                    <div className="password mb-2">
                      <label htmlFor="password">{messages.password_user}</label>
                      <input
                        {...register("password", {
                          required: messages.input_required,
                          pattern: {
                            value:
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                            message: messages.validate_password,
                          },
                        })}
                        type="password"
                        id="password"
                        placeholder={messages.placeholder_password_user}
                      />
                      <div>
                        {errors?.password && (
                          <p>{errors?.password?.message || "Errors"}</p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center">
                      <div>
                        <label htmlFor="gender" className="mr-2">
                          {messages.users_roles}
                        </label>
                      </div>
                      <div className="roles">
                        <Controller
                          name={"roles"}
                          control={control}
                          render={({ field: { value, name, onChange } }) => (
                            <Select
                              name={name}
                              isMulti={true}
                              isSearchable={false}
                              options={props.allRoles.map((role) => ({
                                value: role,
                                label: role.name!,
                              }))}
                              styles={customStyles}
                              value={props.allRoles
                                .filter((role) => value?.includes(role))
                                .map((role) => ({
                                  value: role,
                                  label: role.name!,
                                }))}
                              onChange={(values) =>
                                onChange(
                                  values.map((v) => v.value) ?? undefined,
                                )
                              }
                              placeholder={messages.placeholder_users_roles}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="createBlockSecondColumn">
                    <div className="firstName mb-2">
                      <label htmlFor="firstName">{messages.firstName}</label>
                      <input
                        {...register("firstName")}
                        type="text"
                        id="firstName"
                        placeholder={messages.placeholder_firstName}
                      />
                    </div>
                    <div className="secondName mb-2">
                      <label htmlFor="secondName">{messages.secondName}</label>
                      <input
                        {...register("secondName")}
                        type="text"
                        id="secondName"
                        placeholder={messages.placeholder_secondName}
                      />
                    </div>
                    <div className="dateBirth mb-2">
                      <label htmlFor="dateBirth">{messages.dateBirth}</label>
                      <Controller
                        name={"dateBirth"}
                        control={control}
                        render={({ field: { value, name, onChange } }) => (
                          <DatePicker
                            locale="ru"
                            name={name}
                            selected={value}
                            isClearable
                            calendarStartDay={3}
                            onChange={(value) => onChange(value)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={messages.placeholder_dateBirth}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            disabledKeyboardNavigation
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-wrap items-center">
                      <div>
                        <label htmlFor="gender" className="mr-2">
                          {messages.user_gender}
                        </label>
                      </div>
                      <div className="gender">
                        <Controller
                          name={"gender"}
                          control={control}
                          render={({ field: { value, name, onChange } }) => (
                            <Select
                              name={name}
                              options={optionsDataGender}
                              styles={customStyles}
                              isSearchable={false}
                              isClearable={false}
                              value={optionsDataGender.find(
                                (option) => option.value === value ?? undefined,
                              )}
                              onChange={(value) => onChange(value?.value)}
                              placeholder={messages.placeholder_user_gender}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createButtonUser">
                  <button type="submit" className="btn mr-8">
                    <span>{messages.create}</span>
                  </button>
                  <button
                    type="reset"
                    className="cancel"
                    onClick={() => setShowModalNotification(true)}
                  >
                    <span>{messages.cancel}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateEditUser;
