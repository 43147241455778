import React, { useEffect, useMemo, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import "./UserPage.scss";
import { RoleDTO, UserDTO } from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import { messages } from "../../../i18n/messages";
import { ReactComponent as Confirmed } from "./svg/confirmed.svg";
import { ReactComponent as NotConfirmed } from "./svg/refuse.svg";
import { ReactComponent as ReturnToken } from "./svg/return_token_user.svg";
import { ReactComponent as ReturnAllTokens } from "./svg/return_all_token.svg";
import { ReactComponent as AddUser } from "./svg/addUser.svg";
import { ReactComponent as DeleteUser } from "./svg/trashCanUser.svg";
import { ReactComponent as EditRole } from "./svg/edit.svg";
import { userPageStyles } from "./userPageStyles";
import ModalNotification from "../UpdatePage/Uploader/Modal/ModalNotification";
import Notification from "../../Notification/Notification";
import { NotificationType } from "../../Notification/notificationTypes";
import { Hint, Tooltip } from "@skbkontur/react-ui";
import CreateEditUser from "./CreateEditUser/CreateEditUser";
import { renderConfirmEmail, renderNotConfirmEmail } from "./RenderForToolTip";
import { FilterComponent } from "./FilterComponent";
import { paginationComponentOptions } from "./Pagination";
import {
  caseCreationDateSort,
  caseRefreshTokenExpiryTimeSort,
} from "./FunctionsSort";
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/notificationSlice";
import { AppDispatch } from "../../../store";
import SelectAllTransferRoles from "./ChangeUserRole/SelectAllTransferRoles";

function UserPage(/*props: UserPageProps*/) {
  const [users, setUsers] = useState<Array<UserDTO>>([]);
  const [selectedUser, setSelectedUser] = useState<UserDTO>({} as UserDTO);
  const [showModalUpdateToken, setShowModalUpdateToken] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [allRoles, setAllRoles] = useState<Array<RoleDTO>>([]);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
  const [showModalEditRole, setShowModalEditRole] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [pending, setPending] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  /*Получение списка юзеров*/
  async function getUsers() {
    let response;
    try {
      response = await mapClient.getUsers();
      setUsers(response.data ?? []);
      const foundSelectedUser =
        response.data?.find((u) => u.id === selectedUser?.id) ??
        ({} as UserDTO);
      setSelectedUser(foundSelectedUser);
      let userRole;
      userRole = await mapClient.getRoles();
      setAllRoles(userRole.data ?? []);
    } catch {}
  }
  useEffect(() => {
    getUsers();
  }, []);

  /*Лоадер*/
  useEffect(() => {
    const timeout = setTimeout(() => {
      setUsers(users);
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [users]);
  /*Отзыв токена пользователя*/
  async function revokeToken(userId: string) {
    setShowModalUpdateToken(false);
    try {
      await mapClient.revokeToken(userId);
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.user_revoke_token_success,
        }),
      );
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.user_revoke_token_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await getUsers();
  }
  /*Отзыв всех токенов*/
  async function revokeAllTokens() {
    setShowModalUpdateToken(false);
    try {
      await mapClient.revokeAllTokens();
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.user_revoke_all_token_success,
        }),
      );
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.user_revoke_all_token_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await getUsers();
  }

  /*Удаление юзера*/
  async function deleteUser(id: string) {
    setShowModalDeleteUser(false);
    try {
      await mapClient.deleteUser(id);
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.info,
          message: messages.user_delete_user_success,
        }),
      );
    }catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.user_delete_user_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await getUsers();
  }

  /*Модальное окно для обновления токена*/
  function onShowModalUpdateToken(user: UserDTO) {
    setSelectedUser(user);
    setShowModalUpdateToken(true);
  }
  /*Модальное окно для удаления юзера*/
  function onShowModalDeleteUser(user: UserDTO) {
    setSelectedUser(user);
    setShowModalDeleteUser(true);
  }
  /*Фильтрация юзеров*/
  const filteredUsers = users.filter(
    (user) =>
      user.id?.toLowerCase().includes(filterText.toLowerCase()) ||
      user.email?.toLowerCase().includes(filterText.toLowerCase()) ||
      user.creationDate
        ?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        ?.toLowerCase()
        .includes(filterText.toLowerCase()) ||
      user.roles
        ?.map((r) => r.name)
        .join(", ")
        ?.toLowerCase()
        .includes(filterText.toLowerCase()) ||
      user.refreshTokenExpiryTime
        ?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
        ?.toLowerCase()
        .includes(filterText.toLowerCase()),
  );
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  /*Колонки таблицы юзеров*/
  const columns: TableColumn<UserDTO>[] = [
    {
      name: messages.user_id,
      selector: (user) => user.id ?? "",
      sortable: true,
      compact: true,
      wrap: true,
      grow: 3,
    },
    {
      name: messages.user_email,
      selector: (user) => user.email ?? "-",
      sortable: true,
      grow: 3,
      compact: true,
      wrap: true,
    },
    {
      name: messages.user_email_status,
      selector: (user) =>
        user.emailConfirmed
          ? messages.user_email_confirmed
          : messages.user_email_notConfirmed,
      cell: (user) =>
        user.emailConfirmed ? (
          <Tooltip render={renderConfirmEmail} pos="top center">
            <Confirmed />
          </Tooltip>
        ) : (
          <Tooltip render={renderNotConfirmEmail} pos="top center">
            <NotConfirmed />
          </Tooltip>
        ),
      compact: true,
      sortable: true,
      grow: 2,
    },
    {
      name: messages.user_creation_date,
      selector: (user) =>
        user.creationDate?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }) ?? "-",
      sortable: true,
      sortFunction: caseCreationDateSort,
      compact: true,
      wrap: true,
      grow: 2,
    },
    {
      name: messages.user_roles,
      selector: (user) => user.roles?.map((r) => r.name).join(", ") ?? "",
      sortable: true,
    },
    {
      cell: (selectedUser) => (
        <>
          <Hint text={"Сменить роль"}>
            <EditRole
              onClick={() => {
                setSelectedUser(selectedUser);
                setShowModalEditRole(true);
              }}
            />
          </Hint>
        </>
      ),
    },
    {
      name: messages.user_refresh_token_expiry_time,
      selector: (user) =>
        user.refreshTokenExpiryTime?.toLocaleString("ru", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }) ?? "-",
      sortable: true,
      sortFunction: caseRefreshTokenExpiryTimeSort,
      wrap: true,
      compact: true,
      grow: 2,
    },
    {
      cell: (user) => (
        <>
          <Hint text={messages.user_revoke_token}>
            <ReturnToken
              className="mr-3"
              onClick={() => onShowModalUpdateToken(user)}
            />
          </Hint>
          <DeleteUser onClick={() => onShowModalDeleteUser(user)} />
        </>
      ),
    },
  ];

  return (
    <>
      {showCreateUser ? (
        <CreateEditUser
          setShowCreateUser={setShowCreateUser}
          getUsers={getUsers}
          allRoles={allRoles}
          selectedUser={selectedUser}
        />
      ) : null}
      {showModalUpdateToken ? (
        <div>
          <ModalNotification
            modalQuestion={
              selectedUser.id
                ? messages.user_revoke_token_question
                : messages.user_revoke_all_token_question
            }
            onClose={() => setShowModalUpdateToken(false)}
            onAcceptCloseForm={
              selectedUser.id
                ? () => revokeToken(selectedUser.id!)
                : () => revokeAllTokens()
            }
          />
        </div>
      ) : null}
      {showModalDeleteUser ? (
        <div>
          <ModalNotification
            modalQuestion={messages.user_delete_user_question}
            onClose={() => setShowModalDeleteUser(false)}
            onAcceptCloseForm={() => deleteUser(selectedUser.id!)}
          />
        </div>
      ) : null}
      {showModalEditRole ? (
        <div>
          <SelectAllTransferRoles
            users={users}
            selectedUser={selectedUser}
            setShowModalEditRole={() => setShowModalEditRole(false)}
            allRoles={allRoles}
            getUsers={getUsers}
          />
        </div>
      ) : null}
      <div className="usersList">
        <div className="usersContainer">
          <div className="usersHeader">
            <h2 className="usersTitle">{messages.user_list_title}</h2>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                className="btn mr-4"
                onClick={() => {
                  setSelectedUser({} as UserDTO);
                  setShowCreateUser(true);
                }}
              >
                <AddUser className="mr-2" />
                <span>{messages.create_user}</span>
              </button>
            </div>
            <div className="flex">
             {/* <button className="cancel flex mr-4">
                <DeleteUser className="mr-2" />
                <span>Удалить</span>
              </button>*/}
              <button
                onClick={() => setShowModalUpdateToken(true)}
                className="cancel flex"
              >
                <ReturnAllTokens className="mr-2" />
                <span>{messages.revoke}</span>
              </button>
            </div>
          </div>
          <div className="users_body">
            <DataTable
              columns={columns}
              data={filteredUsers}
              defaultSortFieldId={4}
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              customStyles={userPageStyles}
              fixedHeader={true}
              fixedHeaderScrollHeight="400px"
              striped
              pagination={true}
              paginationPerPage={150}
              /* paginationRowsPerPageOptions={[30, 50]}*/
              paginationComponentOptions={paginationComponentOptions}
              highlightOnHover={true}
              pointerOnHover={true}
              noDataComponent={messages.table_empty}
              onRowDoubleClicked={(selectedUser) => {
                setSelectedUser(selectedUser);
                setShowCreateUser(true);
              }}
              progressPending={pending}
              progressComponent={<Loader />}
              /*selectableRows={(selected)=>{}}
              selectableRowsHighlight={true}*/
            />
          </div>
        </div>
        <Notification />
      </div>
    </>
  );
}

export default UserPage;
