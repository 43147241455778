import { messages } from "../../../i18n/messages";

type PaginationComponentOptionsType = {
  rowsPerPageText: string;
  rangeSeparatorText: string;
  selectAllRowsItem: boolean;
  selectAllRowsItemText: string;
};

export const paginationComponentOptions: PaginationComponentOptionsType = {
  rowsPerPageText: messages.table_page_text,
  rangeSeparatorText: "-",
  selectAllRowsItem: true,
  selectAllRowsItemText: messages.table_select_allPages,
};