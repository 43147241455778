import React from "react";
import "./SessionList.scss";
import { Firmware } from "../../../../api/auth/apiClient";
import { ReactComponent as Check } from "../svg/check.svg";
import { ReactComponent as Fail } from "../svg/fail.svg";
import { ReactComponent as Update } from "../svg/process_update.svg";
import { messages } from "../../../../i18n/messages";

type PropsSessionList = {
  chosenFirmWare: Firmware | undefined;
  setIsShowSessionList: (isShowSessionList: boolean) => void;
};

function SessionList(props: PropsSessionList) {
  return (
    <div className="sessionList">
      <div className="sessionListContainer">
        <div className="sessionListModalForm">
          <div className="sessionListModalFormHeader flex items-center justify-between">
            <h2 className="sessionListModalFormHeaderTitle">
              {messages.session_list}
            </h2>
            <img
              src="/image/btn-remove/btn-remove.svg"
              onClick={() => props.setIsShowSessionList(false)}
              alt="Close form"
            />
          </div>
          <div className="sessionListModalFormBody">
            {props.chosenFirmWare?.updateSessions?.map((session) => (
              <li key={session.id} className="sessionListItem">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="mr-8">
                      <span>{session.id}</span>
                    </div>
                    <div className="mr-8">
                      <span>{session.startDate?.toDateString()}</span>
                    </div>
                    <div className="mr-8 flex items-center justify-between">
                      <span>{session.endDate?.toDateString()}</span>
                      <div className="ml-3">
                        {(() => {
                          if (session?.succeeded === null) {
                            return <Update />;
                          } else if (session?.succeeded === true) {
                            return <Check />;
                          } else if (session?.succeeded === false) {
                            return <Fail />;
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionList;
