import React, { useState } from "react";
import "../CreateEditCompany/CreateEdit.scss";
import { messages } from "../../../../i18n/messages";
import { SubmitHandler, useForm } from "react-hook-form";
import { CompanyDTO } from "../../../../api/auth/apiClient";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import ModalNotification from "../../UpdatePage/Uploader/Modal/ModalNotification";
import { useDispatch } from "react-redux";
import { NotificationType } from "../../../Notification/notificationTypes";
import { showNotification } from "../../../../store/notificationSlice";
import { AppDispatch } from "../../../../store";

type RefreshTokenCompanyDTO = {
  secretCode: string;
};
type UpdateCompanyTokenProps = {
  getCompanies: () => void;
  setShowUpdateToken: (showUpdateToken: boolean) => void;
  currentCompany: CompanyDTO;
};

function UpdateCompanyToken(props: UpdateCompanyTokenProps) {
  const [showModalNotification, setShowModalNotification] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RefreshTokenCompanyDTO>({ mode: "onChange" });

  const onSubmit: SubmitHandler<RefreshTokenCompanyDTO> = async (data) => {
    let response;
    try {
      response = await mapClient.refreshCompanyToken(
        props.currentCompany.id!,
        data.secretCode,
      );
      if (response.succeeded) {
        dispatch(
          showNotification({
            isShow: true,
            type: NotificationType.info,
            message: messages.update_company_token_success,
          }),
        );
        props.setShowUpdateToken(false);
      } else throw response.errors;
    } catch (error: any) {
      dispatch(
        showNotification({
          isShow: true,
          type: NotificationType.error,
          message: `${messages.update_company_token_error}: ${
            error?.errors ? error.errors[0] : "Unknown error"
          }`,
        }),
      );
    }
    await props.getCompanies();
  };

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={messages.update_company_token_question}
            onClose={() => setShowModalNotification(false)}
            onAcceptCloseForm={() => props.setShowUpdateToken(false)}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="createContainer">
          <div className="createForm">
            <div className="createHeader flex justify-between items-center">
              <h3>{messages.update_company_token}</h3>
              <img
                src="/image/btn-remove/btn-remove.svg"
                alt="Escape"
                onClick={() => setShowModalNotification(true)}
              />
            </div>
            <div className="createBlock mt-5">
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                className="createBlockForm"
              >
                <label htmlFor="secretCode">
                  {messages.company_secret_code}
                </label>
                <input
                  {...register("secretCode", {
                    required: messages.input_required,
                  })}
                  type="text"
                  id="text"
                  placeholder={messages.placeholder_secret_code}
                />
                <div>
                  {errors?.secretCode && (
                    <p>{errors?.secretCode?.message || "Errors"}</p>
                  )}
                </div>
                <div className="createButton">
                  <button type="submit" className="btn">
                    <span>{messages.update}</span>
                  </button>
                  <button
                    type="reset"
                    className="cancel"
                    onClick={() => setShowModalNotification(true)}
                  >
                    <span>{messages.cancel}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateCompanyToken;
